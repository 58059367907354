import {format} from 'date-fns'
import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, getBlogUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Img from 'gatsby-image';

function BlogPostPreview (props) {
  return (
  <div className="bg-white border cursor-pointer h-90 h-full m-auto max-w-md overflow-hidden rounded-lg shadow-lg w-full">
    <Link
      className="w-full block h-full"
      to={getBlogUrl(props.slug.current)}
    >
        {props.mainImage && props.mainImage.asset && (
            <Img fluid={props.mainImage.asset.fluid} objectFit="cover" className="w-full" alt={props.mainImage.alt}/>
        )}

        <div className='bg-white dark:bg-gray-800 w-full p-4'>
          <div className="flex items-center mt-4">
            <div className='text-gray-500 dark:text-gray-300 text-base'>{format(props.publishedAt, 'MMMM Do, YYYY')}</div>
          </div>
          <p className='text-gray-800 dark:text-white text-xl font-medium mb-2'>{props.title}</p>
          {props._rawExcerpt && (
            <div className='text-gray-500 dark:text-gray-300 font-light text-md'>
              <PortableText blocks={props._rawExcerpt} />
            </div>
          )}
        </div>
    </Link>
  </div>
  )
}

export default BlogPostPreview
