import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Container from '../components/container'
import Pagination from '../components/pagination'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Footer from '../components/footer';

export const query = graphql`
  query BlogPageQuery($skip: Int = 0, $pageSize: Int = 9) {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: $pageSize
      skip: $skip
      ) {
      totalCount
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              fluid(maxHeight: 450) {
                ...GatsbySanityImageFluid
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

export default function BlogPage ({data, pageContext, errors}) {

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)
  return (
    <Layout>
      <SEO title='Blog' />
      <Container>
        <div className="text-center lg:w-3/4 xl:w-2/3 mx-auto my-16">
          <h1 className="font-bebas-neue uppercase text-5xl sm:text-7xl font-black flex flex-col leading-none dark:text-white text-gray-800">
            Blog
          </h1>
        </div>
      </Container>
      <Container>
        {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid nodes={postNodes} />}
        <Pagination
          pageSize={process.env.GATSBY_PAGE_SIZE}
          totalCount={data.posts.totalCount}
          currentPage={pageContext.currentPage || 1}
          skip={pageContext.skip}
          base="/blog"
        />
      </Container>
      <Footer />
    </Layout>
  )
}
