import {Link} from 'gatsby'
import React from 'react'
import BlogPostPreview from './blog-post-preview'

function BlogPostPreviewGrid (props) {
  return (
    <>
      <div className='header flex items-end justify-between mb-12'>
        <div className="title">
          {props.title && <p className="text-4xl font-bold text-gray-800 mb-4">{props.title}</p>}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
        {props.nodes &&
          props.nodes.map(node => (
              <BlogPostPreview key={node.id} {...node} isInList />
          ))}
      </div>
    </>
  )
}

BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostPreviewGrid
